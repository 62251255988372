import * as React from "react"
import styled from 'styled-components/macro'
import Title from "../../common/Title";
import Subtitle from "../../common/Subtitle";
import Link from "../../common/Link"
import IPhoneImage from '../../images/iphone-image.png'
import TopAngle from "../../common/TopAngle";
import BottomAngle from "../../common/BottomAngle";

const MobileSectionWrapper = styled.div`
  width: 100%;
  color: #f9f9f9;
  padding-bottom: 75px;
  padding-top: 75px;
  position: relative;

  > div:nth-of-type(3) {
    width: calc(100% - 40px);
    max-width: 1100px;
    margin: 0 auto;
  }
  
  @media screen and (max-width: 880px) {
    > div:nth-of-type(3) {
      display: flex;
      flex-direction: column-reverse;
      margin-top: 25px;
      width: 100%;
      max-width: 480px;
    }
  }
`

const ImageWrapper = styled.div`
  width: 40%;
  height: auto;
  display: inline-block;
  vertical-align: top;
  margin-left: -90px;
  margin-top: -160px;
  transform: translateX(40px);
  position: relative;
  
  img {
    width: 94%;
    height: auto;
    position: absolute;
  }
  
  @media screen and (max-width: 1200px) {
    margin-left: -130px;
    
    img {
      margin-left: 30px;
      margin-top: calc(375px - 85%);
    }
  }
  
  @media screen and (max-width: 880px) {
    width: 100%;
    margin: 0;
    transform: none;
    height: 290px;
    margin-bottom: -40px;
    overflow: hidden;
    
    img {
      margin-top: 0;
      position: absolute;
      z-index: 1;
    }
  }
`

const ImageHiderGradient = styled.div`
  display: none;
  width: 100%;
  height: 100%;
  background: rgb(24,24,42);
  background: linear-gradient(0deg, rgba(24,24,42,1) 75%, rgba(24,24,42,0) 100%);
  
  @media screen and (max-width: 880px) {
    position: absolute;
    display: block;
    z-index: 2;
    top: -100px;
    left: -20px;
    height: 450px;
  }
`

const Content = styled.div`
  width: 63%;
  padding: 25px;
  max-width: 990px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  
  @media screen and (max-width: 880px) {
    width: calc(100% - 50px);
    padding: 25px 20px;
    height: 300px;
  }
`

const ContentInner = styled.div`
  @media screen and (max-width: 880px) {
    position: absolute;
    z-index: 3;
    width: calc(100% - 30px);
  }
`

// markup
const MobileSection = () => {

  return (
    <MobileSectionWrapper>
      <TopAngle />
      <BottomAngle />
      <div>
        <Content>
          <ContentInner>
            <Title>
              Margin brought to mobile.
            </Title>
            <Subtitle>
              Until now, DeFi portfolio management, money markets, and DEX margin trading have largely been the domain of desktop traders, leaving those who interact with the crypto world primarily through their smartphone unable to participate or take advantage of a full set of margin tools. No Longer! Dolomite's full set of features is available to smartphone users. Whether you operate solely on a mobile device or would like to be able to open positions on your computer and manage them while on the go, Dolomite makes it simple and accessible.
            </Subtitle>
            <Link text={'Get started'} dest={'https://docs.dolomite.io/guide/depositing-to-dolomite'} breakWidth={410} />
          </ContentInner>
          <ImageHiderGradient />
        </Content>
        <ImageWrapper>
          <img src={IPhoneImage} alt={'Mobile exchange image'} />
        </ImageWrapper>
      </div>
    </MobileSectionWrapper>
  )
}

export default MobileSection
